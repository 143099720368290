body {
  .btn {
    transform: none !important;
  }
}

// navbar
.dunidee-logo {
  width: 150px !important;
}

.navbar-stick:not(.navbar-dark) {
  box-shadow: 0rem 0rem 0.3rem rgba(30, 34, 40, 0.007);
}

$primary-color: #343f52;

@media (min-width: 768px) {
  .instant-quote {
    width: 17rem !important;
  }
}

.device-choice:hover {
  box-shadow: 0 0 0 3pt #FFD80D !important; // outline fix on mobile
  background-color: rgb(255, 255, 225)!important;
}

.quote-form {
  margin-top: 3rem;

  .form-label {
    color: $primary-color;
    font-size: 20px;
    font-weight: bold;

    span {
      color: rgba(28, 136, 122, 0.298);
      font-size: 15px;
      margin: 0 0 0 0.3rem;
    }
  }

  .pointer {
    cursor: pointer
  }

  .pointer-none {
    cursor: initial;
  }

  .quote-card {
    span {
      color: rgba(28, 136, 122, 0.298);
    }
  }

  .selected-quote-card {
    box-shadow: 0 0 0 3pt #FFD80D; // outline fix on mobile
    background-color: rgb(255, 255, 225);

    small {
      color: $primary-color;
      font-weight: bold;
    }
  }

  select {
    font-size: 16px;
    color: $primary-color;
  }

  .form-check-label {
    color: $primary-color;
    cursor: pointer;
  }

  .form-check-input {
    cursor: pointer;
  }

  .custom-features {
    span {
      font-size: 16px;
    }
  }
}

.previous {
  background: transparent !important;
}

// Progression tunnel

.tunnel-progress {

  .display-6 {
    font-size: 1.1rem;
  }

  .process-wrapper.line [class*=col-]:after {
    left: 4rem !important;
    border-top: 2px solid rgb(222, 222, 222);
    top: 0.9rem;
  }

  @media (max-width: 768px) {
    // for mobile

    .process-wrapper.line [class*=col-]:after {
      left: 2rem !important;
    }
  }

  .btn.btn-circle.btn-lg,
  .btn-group-lg>.btn.btn-circle {
    width: 2rem !important;
    height: 2rem;

    .number {
      font-size: 0.9rem;
    }
  }
}

// tooltips

.popover-body {
  ol, ul, dl {
    margin-bottom: 0!important;
    padding-left: 1rem;
  }
}

.icon-svg {
  filter: brightness(0) saturate(100%) invert(34%) sepia(70%) saturate(463%) hue-rotate(144deg) brightness(96%) contrast(92%);
}